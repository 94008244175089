import * as Sentry from "@sentry/vue";

async function lazyLoadSentryIntegrations() {
    // Don't load on server
    if (!process.client) return;

    // Only load on production
    const runtimeConfig = useRuntimeConfig();
    if (runtimeConfig.public.NODE_ENV !== 'production') return;

    Sentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: true,
    });
}

function getSentryIntegrations() {
    // Don't load on server
    if (!process.client) return [];

    // Only load on production
    const runtimeConfig = useRuntimeConfig();
    if (runtimeConfig.public.NODE_ENV !== 'production') return;


    const router = useRouter();
    const browserTracing = Sentry.browserTracingIntegration({
      router,
    });
    return [browserTracing];
}



export default defineNuxtPlugin({
    name: 'sentry',
    parallel: true,
    async setup(nuxtApp) {
        const vueApp = nuxtApp.vueApp;

        const runtimeConfig = useRuntimeConfig();

        // Only initialize Sentry in production
        if (runtimeConfig.public.NODE_ENV !== 'production') return;

        Sentry.init({
            app: vueApp,
            dsn: runtimeConfig.public.SENTRY_DSN_PUBLIC ?? null,
            integrations: getSentryIntegrations(),

            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            // We recommend adjusting this value in production
            tracesSampleRate: runtimeConfig.public.SENTRY_TRACES_SAMPLE_RATE as number,

            // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
            // Need to modify CORS policy & install Sentry on WordPress site to make this useful
            // tracePropagationTargets: [ 'localhost', runtimeConfig.public.SITE_URL, runtimeConfig.public.GRAPHQL_URL],

            // This sets the sample rate. You may want this to be 100% while
            // in development and sample at a lower rate in production
            replaysSessionSampleRate: runtimeConfig.public.SENTRY_REPLAY_SAMPLE_RATE as number,

            // If the entire session is not sampled, use the below sample rate to sample
            // sessions when an error occurs.
            replaysOnErrorSampleRate: runtimeConfig.public.SENTRY_ERROR_REPLAY_SAMPLE_RATE as number,
        });

        // Lazy-load the replay integration to reduce bundle size
        lazyLoadSentryIntegrations();

    }
});
