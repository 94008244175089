let lassoInitialized = ref(false);

export function useLassoCRM() {
  const initLassoCRM = () => {
    const guidNames = [];
    const LassoCRM = window.LassoCRM || {};

    (function (ns) {
      ns.tracker = new LassoAnalytics('LAS-786181-01');

      // Collect all hidden fields with a default value of 'guid'
      const guidInputs = document.querySelectorAll('.dynamic-gravity-form input[value="guid"]');

      // Assign unique names to each GUID input and add to guidNames array
      guidInputs.forEach((input, index) => {
        input.setAttribute('name', 'lasso_guid_input_' + index);
        guidNames.push(input.getAttribute('name'));
      });
    })(LassoCRM);

    try {
      LassoCRM.tracker.setTrackingDomain('//app.lassocrm.com');
      LassoCRM.tracker.init();
      LassoCRM.tracker.track();

      guidNames.forEach(name => {
        LassoCRM.tracker.patchRegistrationForms(name);
      });
    } catch (error) {
      console.error('LassoCRM error:', error);
    }
  };

  const checkScriptLoaded = (src, callback) => {
    const existingScript = document.querySelector(`script[src="${src}"]`);
    if (existingScript) {
      if (existingScript.getAttribute('data-loaded')) {
        callback();
      } else {
        existingScript.addEventListener('load', callback);
      }
    } else {
      const script = document.createElement('script');
      script.src = src;
      script.type = 'text/javascript';
      script.async = true;
      script.addEventListener('load', () => {
        script.setAttribute('data-loaded', 'true');
        callback();
      });
      document.body.appendChild(script);
    }
  };

  const waitForForms = (callback, timeout = 5000) => {
    const interval = 100; // Check every 100ms
    let elapsedTime = 0;

    const checkForms = () => {
      const guidInputs = document.querySelectorAll('.dynamic-gravity-form input[value="guid"]');
      if (guidInputs.length > 0) {
        clearInterval(timer);
        callback();
      } else if (elapsedTime >= timeout) {
        clearInterval(timer);
        console.warn('Forms not found within the timeout period');
      } else {
        elapsedTime += interval;
      }
    };

    const timer = setInterval(checkForms, interval);
  };

  checkScriptLoaded('//app.lassocrm.com/analytics.js', () => {
    if (typeof LassoAnalytics !== 'undefined') {
      waitForForms(initLassoCRM);
      lassoInitialized.value = true;
    } else {
      console.error('LassoAnalytics is not defined even after script load');
    }
  });
}

export function reinitializeLassoCRM() {
  lassoInitialized.value = false;
  useLassoCRM();
}

