<template>
  <div id="error-page" key="error-page" class="max-w-full">
    <OrganismsHeadingTransition
      :organism="{ title1: error.statusCode + ' Error', arrow: true }"
    />

    <div class="max-w-5xl mx-auto text-center">
      <h2 class="my-12 text-2xl">
        Oops! We're sorry, there was a {{ error.statusCode }} error with the
        following message:
      </h2>

      <div class="mb-12">
        <pre
          class="inline-block max-w-full px-4 py-8 overflow-x-scroll bg-gray-200"
          >{{
            isApolloError
              ? "Auth token expired or invalid, attempting to refresh..."
              : error.message
          }}</pre
        >
      </div>

      <div v-if="!isApolloError">
        <h3 class="my-12 text-xl">
          We will attempt to redirect you in 3 seconds, or click the button
          below to go home now.
        </h3>

        <div class="inline-block">
          <NuxtLink
            to="/"
            class="flex items-center justify-center w-full px-8 py-3 font-medium text-center text-white border border-transparent shadow cursor-pointer w-fit button bg-primary-500 rounded-md hover:bg-secondary-500 md:py-4 md:text-lg md:px-10 theme-text-lg"
          >
            Go Home
          </NuxtLink>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import * as Sentry from "@sentry/vue";

const runtimeConfig = useRuntimeConfig();
const env = runtimeConfig.public.NODE_ENV;

const props = defineProps({
  error: Object,
});

const isApolloError = ref(props.error.stack.includes("new ApolloError"));
const router = useRouter();

console.log("Error:", props);

if (isApolloError.value) {
  router.push(props.error.url);
}

onMounted(() => {
  if (env === "production") {
    Sentry.captureException(props.error);
  }

  if (!isApolloError.value) {
    setTimeout(() => {
      clearError({ redirect: "/" });
    }, "3000");
  }
});
</script>
