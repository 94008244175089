<template>
  <div
    :class="[
      'organism heading-transition pt-14',
      Boolean(organism.fullWidth) === false ? '' : 'container-full-width',
      organism.containerWidth === 'full-width' ? 'container-full-width' : '',
      Boolean(organism.arrow) === false ? 'pb-14' : 'arrow pb-[69px]',
    ]"
  >
    <h2
      class="text-3xl sm:text-[40px] font-figtree text-white font-semibold text-center m-0 leading-none px-8 uppercase"
    >
      {{ organism.title1 }}
    </h2>
  </div>
</template>

<style>
.heading-transition {
  @apply bg-theme-gradient;

  &.arrow {
    clip-path: polygon(
      0 0,
      100% 0,
      100% calc(100% - 25px),
      calc(50% - 25px) calc(100% - 25px),
      50% 100%,
      calc(50% + 25px) calc(100% - 25px),
      0 calc(100% - 25px)
    );
  }
}

@media screen and (max-width: 1023px) {
  .heading-transition {
    margin-bottom: 2rem;

    &.arrow {
      clip-path: none;
    }
  }
}
</style>

<script setup>
const props = defineProps({
  organism: Object,
});

onMounted(() => {
  if (props.organism.containerWidth === "full-width") {
    addFullWidthSupport();
  }
});
</script>
